:root {
  --navbarHeight: 90px;
}

/* Scroller Section */

body {
  overflow-x: hidden;
}

.content {
  margin-top: var(--navbarHeight);
}

#scroll-content .section {
  box-sizing: border-box;
}

#scroll-content .vertical-center {
  display: table;
  height: 100%;
  width: 100%;
}

#scroll-content .vertical-center__inner {
  display: table-cell;
  vertical-align: middle;
}

#scroll-content .section--full,
#scroll-content .section--vertical {
  min-height: calc(100vh - var(--navbarHeight));
  position: relative;
}

#scroll-content .block-list {
  white-space: nowrap;
}

#scroll-content .block-list__item {
  width: 100vw;
  height: calc(100vh - var(--navbarHeight));
  min-height: 600px;
  display: inline-block;
  white-space: normal;
  margin-top: var(--navbarHeight);
}

#scroll-content .block-list__item-inner {
  width: 100%;
  height: 100%;
}

#scroll-content .block-list__item:first-child {
  margin-left: 0;
}

#scroll-content .scrollend {
  transform: translateX(-1500px);
}

/* Index styles */

.arrow-container {
  position: relative;
}

.arrow-icon {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  max-width: 100%;
  height: auto;
}

.arrow-go-down {
  position: absolute;
  top: 92%;
  right: 50%;
  transform: translateY(-50%);
  max-width: 100%;
  height: auto;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

/* Section 1 */

.section-1 {
  background-color: #ffffffd9;
}

.section-1 .section1-container {
  display: flex;
  margin-right: 60px;
  margin-left: 60px;
  padding-top: 50px;
}

.section-1 .description-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  align-items: flex-end;
  padding-right: 50px;
}

.section-1 .image-container {
  width: 50%;
}

.section-1 .image-container video {
  width: 100%;
  height: 100%;
  max-width: 534px;
  max-height: 496px;
}

.section-1 .description-container .title {
  text-transform: uppercase;
  color: #575756;
  font-style: normal;
  font-weight: 700;
  font-size: 37px;
  max-width: 574px;
  margin-bottom: 20px;
}

.section-1 .description-container .button {
  background-color: #ecc625;
  border-radius: 39px;
  color: #ffffff;
  font-size: 14px;
  letter-spacing: -0.04em;
  padding: 10px 32px;
  transition: 0.3s !important;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
}

.section-1 .description-container .button:hover {
  background-color: #585857 !important;
}
video {
  max-width: 600px;
  max-height: 382px;
}

/* Section 2 */

.section-2 {
  background-color: #e9c329;
}

.section-2 .section2-container {
  display: flex;
  height: calc(100vh - var(--navbarHeight));
  padding-left: 100px;
  padding-right: 100px;
}

.section-2 .description-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  align-items: flex-end;
  padding-right: 40px;
}

.section-2 .description-container .first-title {
  margin-bottom: -5px;
}

.section-2 .image-container {
  display: flex;
  align-items: end;
  padding-left: 40px;
  width: 50%;
}

.section-2 .image-container img {
  width: 450px;
  height: 580px;
}

.section-2 .section2-container h1 {
  color: #ffffff;
  font-style: normal;
  font-weight: 700;
  font-size: 46px;
  text-transform: uppercase;
}

.section-2 .section2-container p {
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 26px;
  color: #575756;
  margin-top: 15px;
  margin-bottom: 25px;
  white-space: pre-line;
}

.section-2 .description-container .button {
  background-color: #585857;
  border-radius: 39px;
  color: #ffffff;
  font-size: 14px;
  letter-spacing: -0.04em;
  padding: 10px 32px;
  transition: 0.3s !important;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
}

.section-2 .description-container .button:hover {
  background-color: #ffffff;
  color: #585857;
}

.section-2 .description-container .text-max-space {
  max-width: 550px;
}

.section-2 .description-container h1.mobile {
  display: none;
  text-align: center;
}

.section-2 .index-section2-title {
  line-height: normal !important;
  font-size: 26px !important;
}

/* Section 3 */

.section-3 {
  background-color: #ffffffd9;
}

.section-3 .section3-container {
  display: flex;
  flex-direction: column;
  padding-left: 128px;
  padding-right: 128px;
  padding-top: 40px;
  padding-bottom: 40px;
  height: calc(100vh - var(--navbarHeight));
}

.section-3 .section3-container img {
  width: 100%;
  height: 100%;
}

.section-3 .section3-container .row {
  height: 50%;
}

.second-row-col,
.first-row div:first-of-type {
  height: 100%;
}

.section-3 .section3-container .first-row {
  padding-bottom: 5px;
}

.section-3 .section3-container .second-row {
  padding-top: 5px;
}

.section-3 .section3-container .title {
  color: #585857;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
}

.section-3 .section3-container .description {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #585857;
}

.section-3 .section3-container .button {
  background-color: #ecc625;
  border-radius: 39px;
  color: #ffffff;
  font-size: 12px;
  letter-spacing: -0.04em;
  padding: 10px 32px;
  transition: 0.3s !important;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
}

.section-3 .section3-container .button:hover {
  background-color: #585857 !important;
}

.section-3 .section3-container .description-container {
  padding-top: 15px;
  padding-right: 15px;
  padding-left: 15px;
}

/* Section 4 */

.section-4 {
  background-color: #e9c329;
}

.section-4 .section4-container {
  display: flex;
  margin-right: 60px;
  margin-left: 60px;
  padding-top: 50px;
}

.section-4 .description-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  align-items: flex-end;
}

.section-4 .image-container {
  width: 50%;
}

.section-4 .description-container .title {
  text-transform: uppercase;
  color: #ffffff;
  font-style: normal;
  font-weight: 700;
  font-size: 52px;
}

.section-4 .image-container img {
  max-width: 572px;
  max-height: 500px;
}

.section-4 .description-container .description {
  max-width: 495px;
}

/* Section 5 */

.section-5 {
  background-color: #ffffffd9;
}

.specialists-container {
  margin-top: var(--navbarHeight);
  display: flex;
}

.specialists-container .specialists-text {
  background: #d9d9d9;
  border: 0.1px solid #ccc;
  border-radius: 0 55px 55px 0;
  max-width: 360px;
  align-self: flex-start;
}

.specialists-container .specialists-text h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  align-items: center;
  text-transform: uppercase;
  color: #585857;
  padding: 68px 0px 0px 59px;
}

.specialists-container .specialists-text p {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #585857;
  margin: 19px 70px 83px 59px;
}

.specialists-container .card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.specialists-container .card {
  width: 240px;
}

.container .separacion-fila {
  margin-top: 1px;
}

.card-with-border {
  border-radius: 77px 77px 35px 35px !important;
}

.card-text {
  min-height: 48px;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  text-align: center;
  text-transform: uppercase;
  color: #585857;
}

.container .card .button {
  background-color: #585857;
  border-radius: 39px;
  color: #ffffff;
  font-size: 14px;
  letter-spacing: -0.04em;
  padding: 10px 32px;
  transition: 0.3s !important;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  align-self: flex-end;
}

.card-container .card .button:hover {
  background-color: #ecc625 !important;
  color: #585857 !important;
}

.container .hyperlink-container {
  display: flex;
  justify-content: center;
}

.quote-container {
  margin-top: 180px;
  background: #585857;
}

.quote-container .quote {
  font-style: normal;
  /* font-weight: 400; */
  font-size: 30px;
  text-align: center;
  color: #ffffff;
  padding-top: 60px;
  /*padding-left: 60px;*/
  /*padding-right: 60px;*/
  padding-bottom: 40px;

  /*font-style: normal;
  font-weight: 400;
  font-size: 33px;
  text-align: center;
  color: #ffffff;
  padding-top: 81px;
  padding-left: 140px;
  padding-bottom: 40px;
  padding-right: 140px;*/
}

.quote-container .editor {
  font-style: normal;
  /* font-weight: 700; */
  font-size: 30px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  /*font-style: normal;
  font-weight: 700;
  font-size: 38px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;*/
}

.quote-container .position {
  font-style: normal;
  /* font-weight: 700; */
  font-size: 15px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 50px;
  /*font-style: normal;
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 68px;*/
}

.quote-container .hyperlink-container {
  display: flex;
  justify-content: center;
}

.quote-container .button {
  background-color: #ecc625;
  border-radius: 39px;
  color: #ffffff;
  font-size: 14px;
  letter-spacing: -0.04em;
  padding: 10px 32px;
  transition: 0.3s !important;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  margin-bottom: 60px;
  /*margin-bottom: 83px;*/

}

.quote-container .button:hover {
  background-color: #ffffff !important;
  color: #ecc625 !important;
}

.airport-advertising {
  margin-top: 80px;
  margin-left: 130px;
  margin-right: 130px;
  display: flex;
}

.airport-advertising .left-section {
  /*justify-content: left;*/
  width: 50%;
  margin-top: 50px;
}

.airport-advertising .left-section h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  text-transform: uppercase;
  color: #585857;
  display: flex;
  justify-content: center;
  text-align: center;
}

.airport-advertising .left-section .button-container {
  margin-top: 70px;
  display: flex;
  justify-content: center;
}

.airport-advertising .left-section .button {
  background-color: #ecc625;
  border-radius: 39px;
  color: #ffffff;
  font-size: 14px;
  letter-spacing: -0.04em;
  padding: 10px 32px;
  transition: 0.3s !important;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
}

.airport-advertising .left-section .button:hover {
  background-color: #585857 !important;
  color: #ffffff !important;
}

.airport-advertising .right-section {
  width: 50%;
}

.right-section .slick-carousel .slick-slide p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #585857;
  text-align: left;
  white-space: pre-line;
}

.testimonials {
  background-color: #f3f2f2;
}

.testimonials h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  text-transform: uppercase;
  color: #585857;
  display: flex;
  justify-content: center;
  text-align: center;
}

.testimonials .card {
  height: 460px;
}

.our-service {
  padding-top: 70px;
  margin-bottom: 104px;
}

.our-service h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  text-align: center;
  text-transform: uppercase;
  color: #585857;
  margin-bottom: 40px;
}

.our-service .horizontal-carousel .carousel-cell img {
  width: 400px;
  height: 552px;
}

.slick-prev:before,
.slick-next:before {
  color: black;
  position: absolute;
  transform: translateY(-50%) rotate(90deg);
  font-size: 20px;
  width: auto;
  height: 0px;
}

.slick-next,
.slick-prev {
  position: initial;
  margin-left: 50px;
  height: 0;
  width: 0;
}

.slick-prev {
  margin-bottom: 20px;
}

.slick-next {
  margin-top: 20px;
}

/*

.right-section {
  margin-left: 56px;
}

.carousel-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.item-content {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.item-content img {
  margin-right: 37px;
  height: 100px;
  width: 100px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.carousel-item .slick-slide {
  width: 100% !important;
}*/

.testimonials {
  margin-top: 50px;
}

/* Media queries */

@media only screen and (max-width: 1200px) {
  video {
    max-width: 550px !important;
    max-height: 332px !important;
  }

  .section-1 .description-container {
    padding-right: 5px !important;
  }

  .section-2 .section2-container p {
    line-height: 22px !important;
  }

  .section-3 .section3-container {
    padding-left: 60px;
    padding-right: 60px;
  }

  .section-4 .description-container .title {
    font-size: 44px;
  }

  /*section 5*/

  .specialists-container .specialists-text {
    max-width: 275px !important;
  }

  .specialists-container .card {
    width: 220px !important;
  }

  .specialists-container .specialists-text h1 {
    font-size: 20px !important;
  }

  .card-text {
    font-size: 11px !important;
  }

  .card-with-border {
    border-radius: 35px !important;
  }

  .airport-advertising {
    margin-left: 100px !important;
  }

  .right-section .slick-carousel .slick-slide p {
    height: 80px;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 991px) {
  .home-page {
    margin-top: 73px;
  }

  .arrow-icon {
    display: none !important;
  }

  .section-1 .section1-container {
    flex-direction: column-reverse !important;
    align-items: center;
  }
  video {
    max-width: 100% !important;
    max-height: 100% !important;
  }

  .section-1 .description-container {
    width: auto !important;
    padding-right: 0 !important;
    align-items: normal !important;
  }

  .section-1 .image-container {
    width: auto !important;
  }

  .section-1 .description {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .section-1 .section1-container {
    padding-bottom: 80px !important;
  }

  .section-1 .description-container .title {
    text-align: center !important;
    max-width: none !important;
  }

  .section-2 .image-container {
    padding-left: 0 !important;
  }

  .section-2 .description-container {
    padding-right: 0 !important;
  }

  .section-2 .section2-container {
    padding-left: 15px !important;
    padding-right: 15px !important;
    height: auto !important;
  }

  .section-2 .section2-container p {
    font-size: 18px !important;
  }

  .section-3 .section3-container {
    height: auto !important;
  }

  .section-3 .section3-container .title {
    text-align: center;
  }

  .section-3 .section3-container .description-container {
    padding-top: 30px !important;
  }

  .section-3 .section3-container .hyperlink-container {
    display: flex;
    justify-content: center;
  }

  .section-3 .section3-container {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .section-3 .section3-container .description {
    font-size: 16px;
    text-align: justify;
  }

  .section-4 .section4-container {
    padding-bottom: 50px;
    margin-right: 25px !important;
    margin-left: 25px !important;
  }

  .section-4 .description-container .description {
    max-width: none !important;
  }

  .section-4 .description-container .title {
    font-size: 36px !important;
  }

  .section-4 .image-container img {
    max-width: none !important;
    max-height: none !important;
    width: 100%;
    height: 100%;
  }

  /* section 5*/

  .specialists-container {
    flex-direction: column;
  }

  .specialists-container .specialists-text {
    max-width: 100% !important;
    margin-left: 35px !important;
    margin-right: 35px !important;
    margin-bottom: 35px !important;
    border-radius: 55px !important;
    text-align: center !important;
  }

  .specialists-container .specialists-text h1 {
    padding: 0 !important;
    margin-top: 35px !important;
    margin-left: 25px !important;
    margin-right: 25px !important;
  }

  .specialists-container .specialists-text p {
    margin-bottom: 35px !important;
  }

  .container .card {
    margin: auto !important;
  }

  .card-with-border {
    border-radius: 115px 115px 35px 35px !important;
  }

  .quote-container .quote {
    font-size: 25px !important;
  }

  .airport-advertising {
    flex-direction: column !important;
  }

  .airport-advertising {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }

  .airport-advertising .left-section {
    text-align: center !important;
    width: 100% !important;
    margin-top: 0 !important;
  }

  .airport-advertising .right-section {
    width: 100%;
    margin: auto !important;
    margin-top: 80px !important;
  }

  .right-section .slick-carousel .slick-slide p {
    height: auto !important;
  }

  .slick-carousel .slick-slide {
    height: 117px !important;
  }

  /*.airport-advertising .right-section {
    text-align: center !important;
    width: 100% !important;
    margin-top: 80px !important;
    margin-left: 0 !important;
  }

  .airport-advertising .right-section .slick-carousel {
    
  }*/

  .testimonials .card {
    height: 575px !important;
  }

  .our-service .horizontal-carousel .carousel-cell img {
    width: 350px !important;
    height: 502px !important;
  }
}

@media only screen and (max-width: 768px) {
  .section-1 .description-container .title {
    font-size: 30px;
  }

  .section-2 .section2-container {
    flex-direction: column;
  }

  .section-2 .description-container {
    width: auto !important;
    align-items: normal !important;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .section-2 .description-container .text-max-space {
    max-width: none !important;
  }

  .section-2 .description-container h1.mobile {
    display: block !important;
  }

  .section-2 .description-container h1.web {
    display: none !important;
  }

  .section-2 .image-container {
    width: auto !important;
    justify-content: center;
  }

  .section-2 .image-container img {
    width: 100% !important;
    height: 100% !important;
    max-width: 450px;
    max-height: 580px;
  }

  .section-2 .description-container .hyperlink-container {
    display: flex;
    justify-content: center;
  }

  .section-2 .section2-container h1 {
    font-size: 25px;
  }

  .section-2 .section2-container p {
    margin-left: 15px;
    margin-right: 15px;
  }

  .section-3 .section3-container .row {
    height: auto !important;
  }

  .section-3 .section3-container .first-row {
    padding-bottom: 15px !important;
  }

  .section-3 .section3-container .second-row {
    padding-top: 0 !important;
  }

  .section-3 .section3-container .second-row div:first-of-type {
    padding-bottom: 15px;
  }

  .section-3 .section3-container {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .section-4 .description-container .title {
    font-size: 25px !important;
  }

  /* section 5*/

  .specialists-container .card {
    width: 250px !important;
  }

  .quote-container .quote {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .quote-container .editor {
    font-size: 25px !important;
  }

  .quote-container .position {
    padding-left: 30px !important;
    padding-right: 30px !important;
    font-size: 10px !important;
  }

  .slick-carousel .slick-slide {
    height: auto !important;
  }

  .testimonials .card {
    height: auto !important;
  }
}

@media only screen and (max-width: 576px) {
  .section-1 .section1-container {
    margin-right: 15px;
    margin-left: 15px;
  }

  .section-4 .section4-container {
    margin-right: 15px !important;
    margin-left: 15px !important;
  }

  .section-4 .description-container .title {
    font-size: 18px !important;
  }

  /*section 5*/

  .specialists-container .specialists-text p {
    margin: 19px 19px 19px 19px !important;
  }

  .airport-advertising .left-section h1 {
    font-size: 25px !important;
  }

  .quote-container {
    margin-top: 50px !important;
  }

  .slick-carousel .slick-slide {
    height: 140px !important;
  }

  .right-section .slick-carousel .slick-slide p {
    font-size: 12px !important;
  }

  .our-service {
    padding-top: 25px !important;
  }

  .our-service .horizontal-carousel .carousel-cell img {
    width: 310px !important;
    height: 462px !important;
  }

  .our-service h1 {
    margin-bottom: 15px !important;
    font-size: 35px;
  }
}